import React, {useEffect, useRef, useState} from 'react';

import {Link} from "gatsby";
import Logo from "../Logo/Logo";
import Actions from "./Actions/Actions";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./Header.scss"

Header.propTypes = {};

function Header() {
    const header = useRef(null);
    const [isBurgerOpen, setIsBurgerOpen] = useState(false)

    useEffect(() => {
        /**
         * This function changes header background color on scroll via CSS
         * @param {Object} event
         */
        const handleScroll = (event) => {
            if (!header.current) return;
            if (document?.documentElement?.scrollTop > header?.current?.clientHeight) {
                header.current.classList.add("active");
            } else {
                header?.current.classList?.remove("active");
            }
        }
        document?.addEventListener("scroll", handleScroll)
        return () => {
            document?.removeEventListener("scroll", handleScroll)
        }
    }, []);

    const handleClick = () => {
        if (isBurgerOpen) {
            document?.body?.classList?.remove("lock-scroll");
        } else {
            document?.body?.classList?.add("lock-scroll");
        }
        setIsBurgerOpen(prevState => !prevState);
    }

    const handleLinkClick = () => {
        document?.body?.classList?.remove("lock-scroll");
        setIsBurgerOpen(false);
    }

    return (
        <header ref={header} className="header">
            <div className="header__inner wrapper">
                <Logo/>
                <div className="header-nav">
                    <button
                        onClick={handleClick}
                        className={isBurgerOpen ? "header-nav__burger active" : "header-nav__burger"}
                    >
                        <span/>
                    </button>
                    <nav className={isBurgerOpen ? "header-nav__body active" : "header-nav__body"}>
                        <ul className="header-nav__list">
                            <li className="header-nav__item header-nav__item--mobile">
                                <Link onClick={handleLinkClick} activeClassName="active" className="header-nav__link link" to="/"> Home </Link>
                            </li>
                            <li className="header-nav__item">
                                <AnchorLink
                                    onAnchorLinkClick={handleLinkClick}
                                    gatsbyLinkProps={{
                                        activeClassName: "active"
                                    }}
                                    className="header-nav__link link"
                                    to="/#services"
                                    title="What we can"
                                >
                                    What we can
                                </AnchorLink>
                            </li>
                            <li className="header-nav__item">
                                <AnchorLink
                                    onAnchorLinkClick={handleLinkClick}
                                    gatsbyLinkProps={{
                                        activeClassName: "active"
                                    }}
                                    className="header-nav__link link"
                                    to="/#about-us"
                                    title="About us"
                                >
                                    About us
                                </AnchorLink>
                            </li>
                            <li className="header-nav__item">
                                <Link onClick={handleLinkClick} activeClassName="active" className="header-nav__link link"
                                      to="/portfolio"> Portfolio </Link>
                            </li>
                        </ul>
                        <Actions isMobile handleLinkClick={handleLinkClick}/>
                    </nav>
                </div>
                <Actions isMobile={false} handleLinkClick={handleLinkClick}/>
            </div>
        </header>
    );
}

export default Header;