import React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./Actions.scss";

Actions.propTypes = {
    isMobile: PropTypes.bool,
    handleLinkClick: PropTypes.func
};

function Actions( {isMobile, handleLinkClick} ) {
    return (
        <div className={
            isMobile ?
                "header-actions header-actions--mobile" :
                "header-actions header-actions--desktop"
        }
        >
            <AnchorLink
                to="#contact-us"
                title="Hire us"
                className="header-actions__link hire-us-link link"
                onAnchorLinkClick={handleLinkClick}
            >
                Hire us
            </AnchorLink>
            <div className="header-actions__languages">
                <a href="#" className="header-actions__language link">EN</a>
                <a href="#" className="header-actions__language link">RU</a>
            </div>
        </div>
    );
}

export default Actions;