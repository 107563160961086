import React from 'react';

import "./ContactUs.scss"
import Form from "./Form/Form";

ContactUs.propTypes = {};

function ContactUs() {
    return (
        <section className="contact-us" id="contact-us">
            <div className="contact-us__inner">
                <h2 className="section-heading contact-us__heading">Want to do something bigger?</h2>
                <p className="section-subheading contact-us__subheading">Want to do something bigger?</p>
                <Form/>
            </div>
        </section>
    );
}

export default ContactUs;