import React from 'react';
import {Formik} from "formik";
import NumberFormat from "react-number-format";
import * as Yup from "yup"

import "./Form.scss"

Form.propTypes = {};

const validationSchema = Yup.object().shape({
    name: Yup
        .string()
        .required("Enter a valid name"),
    phoneNumber: Yup
        .string()
        .trim()
        .min(13)
        .required("Enter a valid phone number"),
    email: Yup
        .string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Enter a valid email")
        .required("Enter a valid email")
});

function Form() {
    return (
        <Formik
            initialValues={{
                name: "",
                phoneNumber: "",
                email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                console.log(values)
            }}
        >
            {
                ({
                     handleBlur,
                     handleSubmit,
                     handleChange,
                     touched,
                     errors,
                     values
                 }) => (

                    <form
                        onSubmit={handleSubmit}
                        id="contact-us__form"
                        className="contact-us__form contact-form"
                    >
                        <label className="contact-form__label">
                            Name *
                            <input
                                name="name"
                                className="contact-form__input"
                                placeholder="Write your name"
                                value={values.name}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.name && touched.name && <p className="contact-form__error">{errors.name}</p>}
                        </label>
                        <label className="contact-form__label">
                            Phone number
                            <NumberFormat
                                format="+380 (##) ### ## ##"
                                name="phoneNumber"
                                allowEmptyFormatting
                                className="contact-form__input"
                                placeholder="Write your number"
                                value={values.phoneNumber}
                                type="text"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.phoneNumber && touched.phoneNumber &&
                            <p className="contact-form__error">{errors.phoneNumber}</p>}
                        </label>
                        <label className="contact-form__label">
                            Email *
                            <input
                                name="email"
                                className="contact-form__input"
                                placeholder="Write your Email"
                                value={values.email}
                                type="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.email && touched.email && <p className="contact-form__error">{errors.email}</p>}
                        </label>
                        <ul className="contact-form__list">
                            <li className="contact-form__text">On what stage is your product?</li>
                            <li>
                                <label className="contact-form__radio-label">
                                    <input
                                        name="stage"
                                        className="contact-form__radio"
                                        type="radio"
                                    />
                                    <span className="contact-form__radio-checkmark"/>
                                    You have an idea and want to discuss it
                                </label>
                            </li>
                            <li>
                                <label className="contact-form__radio-label">
                                    <input
                                        name="stage"
                                        className="contact-form__radio"
                                        type="radio"
                                        checked
                                    />
                                    <span className="contact-form__radio-checkmark"/>
                                    You already run a project
                                </label>
                            </li>
                        </ul>
                        <button
                            type="submit"
                            className="contact-form__submit"
                        >
                            Send
                        </button>
                    </form>
                )
            }
        </Formik>
    );
}

export default Form;