import React from 'react';
import PropTypes from "prop-types";
import {Link} from "gatsby";

import logo from "../../assets/img/svg/logo.svg"

Logo.propTypes = {
    className: PropTypes.string,
}

Logo.defaultProps = {
    className: ""
}

function Logo ({className})  {
    return (
        <Link to="/" className={`${className} logo`}>
            <img src={logo} alt="Site logo - BOLD"/>
        </Link>
    );
}

export default Logo;