import React from 'react';
import Logo from "../Logo/Logo";

import "./Footer.scss"

Footer.propTypes = {};

function Footer() {

    return (
        <footer className="footer">
            <div className="footer__inner wrapper">
                <div className="footer__details">
                    <Logo className="footer__logo"/>
                    <p className="footer__slogan">Bold, we will find a unique solution to solve your problem</p>
                    <p className="footer__made-by footer__made-by--desktop">@ made by BOLD</p>
                </div>
                <address className="footer__address">
                    <ul className="footer__list social-media">
                        <li className="social-media__item">
                            <a href="#">
                                <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse id="facebook__logo" className="social-media__icon--logo" cx="18.118998"
                                             cy="18.019281" rx="17.018322" ry="17.018324"/>
                                    <path
                                        d="M 18.057998,0.15177125 C 8.116798,0.15177125 0.058,8.2105712 0.058,18.151772 c 0,9.9413 8.058798,18 17.999998,18 9.9413,0 18,-8.0587 18,-18 0,-9.9412008 -8.0587,-18.00000075 -18,-18.00000075 z m 4.2638,12.43870075 h -2.7057 c -0.3206,0 -0.6768,0.4219 -0.6768,0.9825 v 1.9538 h 3.3843 l -0.5119,2.7863 h -2.8724 v 8.3643 h -3.1932 v -8.3643 h -2.8969 v -2.7863 h 2.8969 v -1.6388 c 0,-2.3512 1.6313,-4.2617998 3.87,-4.2617998 h 2.7057 z"
                                        id="facebook__background" className="social-media__icon--background"/>
                                </svg>
                            </a>
                        </li>
                        <li className="social-media__item">
                            <a href="#">
                                <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                    <circle className="social-media__icon--background" cx="18" cy="18" r="18"/>
                                    <path className="social-media__icon--logo"
                                          d="M21.4453 18C21.4453 19.9028 19.9028 21.4453 18 21.4453C16.0972 21.4453 14.5547 19.9028 14.5547 18C14.5547 16.0972 16.0972 14.5547 18 14.5547C19.9028 14.5547 21.4453 16.0972 21.4453 18Z"/>
                                    <path className="social-media__icon--logo"
                                          d="M26.0574 11.9042C25.8918 11.4554 25.6275 11.0492 25.2842 10.7158C24.9508 10.3724 24.5448 10.1082 24.0958 9.9426C23.7316 9.80115 23.1844 9.63278 22.1767 9.58691C21.0866 9.5372 20.7598 9.52649 18 9.52649C15.24 9.52649 14.9131 9.53693 13.8233 9.58664C12.8156 9.63278 12.2682 9.80115 11.9042 9.9426C11.4552 10.1082 11.049 10.3724 10.7158 10.7158C10.3725 11.0492 10.1082 11.4551 9.94235 11.9042C9.8009 12.2684 9.63254 12.8158 9.58667 13.8235C9.53696 14.9134 9.52625 15.2402 9.52625 18.0002C9.52625 20.76 9.53696 21.0869 9.58667 22.177C9.63254 23.1847 9.8009 23.7318 9.94235 24.096C10.1082 24.5451 10.3722 24.951 10.7155 25.2845C11.049 25.6278 11.4549 25.892 11.904 26.0576C12.2682 26.1993 12.8156 26.3677 13.8233 26.4136C14.9131 26.4633 15.2397 26.4737 17.9997 26.4737C20.76 26.4737 21.0869 26.4633 22.1765 26.4136C23.1842 26.3677 23.7316 26.1993 24.0958 26.0576C24.9972 25.7099 25.7097 24.9974 26.0574 24.096C26.1988 23.7318 26.3672 23.1847 26.4133 22.177C26.463 21.0869 26.4735 20.76 26.4735 18.0002C26.4735 15.2402 26.463 14.9134 26.4133 13.8235C26.3675 12.8158 26.1991 12.2684 26.0574 11.9042ZM18 23.3075C15.0686 23.3075 12.6922 20.9314 12.6922 18C12.6922 15.0685 15.0686 12.6925 18 12.6925C20.9312 12.6925 23.3075 15.0685 23.3075 18C23.3075 20.9314 20.9312 23.3075 18 23.3075ZM23.5173 13.723C22.8323 13.723 22.277 13.1676 22.277 12.4826C22.277 11.7976 22.8323 11.2423 23.5173 11.2423C24.2023 11.2423 24.7577 11.7976 24.7577 12.4826C24.7574 13.1676 24.2023 13.723 23.5173 13.723Z"/>
                                    <path className="social-media__icon--background"
                                          d="M18 0C8.06039 0 0 8.06039 0 18C0 27.9396 8.06039 36 18 36C27.9396 36 36 27.9396 36 18C36 8.06039 27.9396 0 18 0ZM28.2736 22.2613C28.2236 23.3616 28.0486 24.1128 27.7932 24.7703C27.2563 26.1587 26.1587 27.2563 24.7703 27.7932C24.1131 28.0486 23.3616 28.2233 22.2616 28.2736C21.1594 28.3239 20.8073 28.3359 18.0003 28.3359C15.193 28.3359 14.8412 28.3239 13.7387 28.2736C12.6387 28.2233 11.8872 28.0486 11.2299 27.7932C10.54 27.5337 9.91544 27.1269 9.39908 26.6009C8.87338 26.0848 8.46661 25.46 8.20706 24.7703C7.95163 24.1131 7.77667 23.3616 7.72668 22.2616C7.67587 21.1591 7.66406 20.807 7.66406 18C7.66406 15.193 7.67587 14.8409 7.72641 13.7387C7.7764 12.6384 7.95108 11.8872 8.20651 11.2297C8.46606 10.54 8.87311 9.91516 9.39908 9.39908C9.91516 8.87311 10.54 8.46634 11.2297 8.20679C11.8872 7.95135 12.6384 7.77667 13.7387 7.72641C14.8409 7.67615 15.193 7.66406 18 7.66406C20.807 7.66406 21.1591 7.67615 22.2613 7.72668C23.3616 7.77667 24.1128 7.95135 24.7703 8.20651C25.46 8.46606 26.0848 8.87311 26.6012 9.39908C27.1269 9.91544 27.5339 10.54 27.7932 11.2297C28.0489 11.8872 28.2236 12.6384 28.2739 13.7387C28.3241 14.8409 28.3359 15.193 28.3359 18C28.3359 20.807 28.3241 21.1591 28.2736 22.2613Z"/>
                                </svg>
                            </a>
                        </li>
                        <li className="social-media__item">
                            <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                <ellipse className="social-media__icon--background" cx="18.293999" cy="18.006002"
                                         rx="17.478865" ry="17.478867"/>
                                <path
                                    d="m 14.5568,16.4993 c 1.057,-0.2063 2.0883,-0.4899 3.0679,-0.7993 -1.3019,-2.3848 -2.9132,-4.5375 -4.8082,-6.355 -2.7843,1.4952 -4.9027,4.1636 -5.5859,7.3476 2.0496,0.4125 4.5805,0.361 7.3262,-0.1933 z"
                                    className="social-media__icon--logo"/>
                                <path
                                    d="m 24.9857,10.5824 -0.1805,0.709 C 24.5217,12.4257 22.124,14.05 18.9787,15.223 l -0.1418,0.0516 C 17.5607,12.864 15.9494,10.6726 14.0674,8.7777 15.2791,8.3008 16.6068,8 17.9861,8 c 2.4621,0 4.7566,0.868 6.5871,2.2472 0.1418,0.1031 0.2836,0.2063 0.4125,0.3352 z"
                                    className="social-media__icon--logo"/>
                                <path
                                    d="m 19.3394,19.5285 c -0.3351,-0.9152 -0.709,-1.8047 -1.1215,-2.6684 -1.0827,0.361 -2.23,0.6704 -3.4031,0.9024 -1.2762,0.2578 -2.939,0.4898 -4.6921,0.4898 -1.0055,0 -2.0798,-0.0773 -3.0981,-0.2708 -0.0258,0.3352 0.0043,0.6704 0.0043,1.0185 0,3.3902 1.5469,6.4194 3.9574,8.4304 0.0129,-0.0774 0.0258,-0.1547 0.0386,-0.2321 0.4899,-2.9647 3.9189,-6.0971 8.3145,-7.6698 z"
                                    className="social-media__icon--logo"/>
                                <path
                                    d="m 19.7647,20.7402 c 0.8766,2.7586 1.3664,5.7536 1.4179,8.7829 -1.0054,0.3094 -2.0883,0.477 -3.1968,0.477 -2.0754,0 -4.0219,-0.6231 -5.6719,-1.6286 -0.0645,-0.3094 -0.0645,-0.6316 -0.0128,-0.9668 0.3996,-2.4363 3.5964,-5.2593 7.4636,-6.6645 z"
                                    className="social-media__icon--logo"/>
                                <path
                                    d="m 28.8701,20.5598 c -0.5413,3.777 -3.0464,6.9351 -6.4238,8.4434 -0.0902,-2.9907 -0.6059,-5.904 -1.4695,-8.6497 2.8875,-0.7992 5.6247,-0.7089 7.8933,0.2063 z"
                                    className="social-media__icon--logo"/>
                                <path
                                    d="m 28.9862,19 v 0.232 c -2.4621,-0.9152 -5.3797,-0.9539 -8.4219,-0.0902 -0.3351,-0.9281 -0.7219,-1.8304 -1.1344,-2.707 2.1189,-0.7956 6.0731,-2.6265 6.6258,-4.834 1.7919,1.9464 2.9305,4.5503 2.9305,7.3992 z"
                                    className="social-media__icon--logo"/>
                            </svg>
                        </li>
                        <li className="social-media__item">
                            <svg viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                <path className="social-media__icon--background"
                                      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"/>
                                <path className="social-media__icon--logo"
                                      d="M24.4528 14.8693C19.2538 14.8693 19.2471 20.0487 19.2471 20.0754C19.2471 20.0754 18.8909 25.2612 24.4528 25.2612C24.4528 25.2612 29.0913 25.525 29.0913 21.6521H26.7095C26.7095 21.6521 26.7884 23.1101 24.5321 23.1101C24.5321 23.1101 22.1503 23.2686 22.1503 20.7546H29.1706C29.1706 20.7546 29.9426 14.8693 24.4528 14.8693ZM22.1237 18.9401C22.1237 18.9401 22.4141 16.8552 24.5058 16.8552C26.6039 16.8552 26.5709 18.9401 26.5709 18.9401H22.1237ZM16.1729 17.6076C16.1729 17.6076 18.238 17.4558 18.238 15.0278C18.238 12.6066 16.5489 11.4187 14.4046 11.4187H7.35095V24.9773H14.4039C14.4039 24.9773 18.7125 25.1095 18.7125 20.9725C18.7129 20.9725 18.8977 17.6076 16.1729 17.6076ZM10.4589 13.8268H14.4043C14.4043 13.8268 15.3611 13.8268 15.3611 15.2386C15.3611 16.6504 14.8002 16.8552 14.1601 16.8552H10.4589V13.8268ZM14.2066 22.5692H10.4589V18.9401H14.4043C14.4043 18.9401 15.8363 18.927 15.8295 20.8073C15.8295 22.3911 14.7675 22.556 14.2066 22.5692ZM21.5365 12.217V13.8865H27.1318V12.217H21.5365Z"/>
                            </svg>
                        </li>
                        <li className="social-media__item">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path className="social-media__icon--background"
                                      d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05888 27.9411 0 18 0C8.05888 0 0 8.05888 0 18C0 27.9411 8.05888 36 18 36Z"/>
                                <g>
                                    <path className="social-media__icon--logo"
                                          d="M21.5836 18.1127L29.0004 22.8015V13.226L21.5836 18.1127Z"/>
                                    <path className="social-media__icon--logo"
                                          d="M7 13.226V22.8015L14.4167 18.1127L7 13.226Z"/>
                                    <path className="social-media__icon--logo"
                                          d="M27.625 10.4375H8.37501C7.68888 10.4375 7.14438 10.949 7.04126 11.6076L18 18.8278L28.9588 11.6076C28.8556 10.949 28.3111 10.4375 27.625 10.4375Z"/>
                                    <path className="social-media__icon--logo"
                                          d="M20.3233 18.9433L18.3777 20.2248C18.2622 20.3004 18.1316 20.3375 17.9996 20.3375C17.8676 20.3375 17.737 20.3004 17.6215 20.2248L15.6758 18.9419L7.04358 24.402C7.14945 25.0551 7.6912 25.5625 8.37458 25.5625H27.6246C28.308 25.5625 28.8497 25.0551 28.9556 24.402L20.3233 18.9433Z"/>
                                </g>
                            </svg>
                        </li>
                    </ul>
                </address>
                <p className="footer__made-by footer__made-by--mobile">@ made by BOLD</p>
            </div>
        </footer>
    );
}

export default Footer;