const PRIMARY_COLOR = "#2847FB";
const PRIMARY_TEXT_COLOR = "#DAE0F4";

const MOBILE_WINDOW_SIZE = 320;
const LARGE_MOBILE_WINDOW_SIZE = 576;
const TABLET_WINDOW_SIZE = 768;
const DESKTOP_WINDOW_SIZE = 992;
const LARGE_DESKTOP_WINDOW_SIZE = 1200;
const EXTRA_LARGE_DESKTOP_WINDOW_SIZE = 1400;

const MOBILE = "mobile";
const LARGE_MOBILE = "large-mobile";
const TABLET = "tablet";
const DESKTOP = "desktop";
const LARGE_DESKTOP = "large-desktop";
const EXTRA_LARGE_DESKTOP = "extra-large-desktop";

const DEVICE_BREAKPOINTS = {
    MOBILE_WINDOW_SIZE,
    LARGE_MOBILE_WINDOW_SIZE,
    TABLET_WINDOW_SIZE,
    DESKTOP_WINDOW_SIZE,
    LARGE_DESKTOP_WINDOW_SIZE,
    EXTRA_LARGE_DESKTOP_WINDOW_SIZE
};

const DEVICES = {
    MOBILE,
    LARGE_MOBILE,
    TABLET,
    DESKTOP,
    LARGE_DESKTOP,
    EXTRA_LARGE_DESKTOP,
};

const PALETTE = {
    PRIMARY_COLOR,
    PRIMARY_TEXT_COLOR
}

export {DEVICE_BREAKPOINTS, DEVICES, PALETTE};